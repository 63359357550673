@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  color: #0e0f10;
  font-style: normal;
  font-display: swap;
} */

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased; /* For iOS */
  -moz-osx-font-smoothing: grayscale; /* For macOS */
  text-rendering: optimizeLegibility;
  height: "100%";
}

.authCodeVerificationInput {
  width: 47px;
  height: 47px;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #0E0F10;
  font-family: "Poppins";
  border: 1px solid #ABB7C0;
  border-radius: 8px;
  background: #fff;
  background-clip: padding-box;
}

.authCodeVerificationInput:focus {
  outline: none;
  border: 1px solid #5B87EF;
}

.authCodeVerificationContainer {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
}

/* react tostify */
.toast-message-success {
  background: #62CDB4 !important;
  color: #fff !important;
}

.toast-message-error {
  background:#E73E3E !important;
  color: #fff !important;
}

.toast-message-warn {
  background: #FFCF54 !important;
  color: #fff !important;
}

.toast-message-info {
  background: #ABB7C0 !important;
  color: #fff !important;
}

/* Custom Scrollbar */
.notification-dropdown-scroll::-webkit-scrollbar {
  width: 8px;
  margin-right: 4px;
}
.notification-dropdown-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
}
.notification-dropdown-scroll::-webkit-scrollbar-thumb {
  background-color: #ABB7C0;
  border-radius: 8px;
  /* outline: 1px solid slategrey; */
}

.content-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 8px;
}
.content-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.content-scroll::-webkit-scrollbar-thumb {
  background-color: #ABB7C0;
  /* outline: 1px solid slategrey; */
}

.scroll-lightGray::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 8px;
}
.scroll-lightGray::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
}
.scroll-lightGray::-webkit-scrollbar-thumb {
  background-color: #ECEFF1;
  /* outline: 1px solid slategrey; */
}


.content-header-wave-white {
  display: block;
  position: relative;
  height: 89px;
  width: 100%;
  background: linear-gradient(82.43deg, #5A3BC7 7.57%, #5B87EF 88.27%);
  transform: scale(1, 1);
  z-index: 0;
}

.content-header-wave-white:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background: #F6F9FC;
  right: -25%;
  top: 69px;
  z-index: 0;
}

.content-header-wave-white:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background: linear-gradient(82.43deg, #5A3BC7 36.57%, #5B87EF 97.27%);
  left: -25%;
  top: -191px;
  z-index: 0;
  clip-path: ellipse(100% 15% at -15% 100%);
}

.content-header-wave {
  display: block;
  position: relative;
  height: 89px;
  width: 100%;
  background: linear-gradient(82.43deg, #5A3BC7 7.57%, #5B87EF 88.27%);
  transform: scale(1, 1);
}

.content-header-wave:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background: linear-gradient(180deg, #F6F9FC 0%, #C6E7FF 200%);
  right: -25%;
  top: 69px;
  z-index: 0;
}

.content-header-wave:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background: linear-gradient(82.43deg, #5A3BC7 36.57%, #5B87EF 97.27%);
  left: -25%;
  top: -191px;
  z-index: 0;
  clip-path: ellipse(100% 15% at -15% 100%);
}

.react-transform-wrapper {
  width: 100% !important
}

/* intercom customize */
.intercom-messenger-frame {
  max-height: 550px !important;
}

/* Forgot password */
.resent-reset-email-tooltip-container{
  max-width: 310px;
}

.report-iframe-wrapper::before {
  padding-top: 48%;
  content: "";
  display: block;
  min-height: 250px;
}